import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button, Grid } from '@material-ui/core';
import axios from 'axios';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import DragDrop from '../../components/Upload/DragDrop'
// import { DropzoneArea } from 'material-ui-dropzone';
// import ButtonUpload from '../../components/Upload/ButtonUpload'

// select components
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// import needed components, functions and styles
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        direction: 'column',
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    inputsGrid: {
        width: '100%'
    },
    //notifcation
    toastsContainer: {
        width: 400,
        marginTop: theme.spacing(6),
        right: 0,
    },
    fieldsStyle: {
        width: '100%', marginTop: 20
    }
}));

export default function _Form({ data }) {
    const classes = useStyles();
    const [id, setId] = React.useState(data ? data[0] : null);
    const [password, setPassword] = React.useState();
    const [firstName, setFirstName] = React.useState(data ? data[2] : '');
    const [lastName, setLastName] = React.useState(data ? data[3] : '');
    const [email, setEmail] = React.useState(data ? data[4] : '');
    const [role, setRole] = React.useState(data ? data[5] : 'admin')
    //handel axios errors
    const errorHandler = error => {
        if (error.response.status) {
            switch (error.response.status) {
                case 403:
                    toast.error('شما برای این عملیات دسترسی ها لازم را ندارید.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    break;
                case 401:
                    toast.error('شما برای این عملیات دسترسی ها لازم را ندارید.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    break;
            }
            return
        }
        toast.error(error.response.status + '\n' + error.response.data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
        return
    }
    //add user
    const addUser = () => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/users`,
            data: {
                firstName,
                lastName,
                role,
                email,
                password: password,
            },
            headers: {
                Authorization: ('bearer ' + localStorage.getItem("id_token"))
            }
        }).then((response) => {
            toast.success('New user added successfully.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }, (res, error) => {
            errorHandler(error)
        })
    };
    //update user
    const updateUser = () => {
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/users/${id}`,
            data: {
                firstName,
                lastName,
                role,
                email,
            },
            headers: {
                Authorization: ('bearer ' + localStorage.getItem("id_token"))
            }
        }).then((response) => {
            toast.success('User data updated successfully.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }, (error) => {
            errorHandler(error)
        })
    };

    return (
        <form id='sliderForm' className={classes.root} noValidate autoComplete="off">
            <Grid item container className={classes.inputsGrid}>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* Same as */}
                <ToastContainer />

                <TextField label="First Name" variant="outlined" className={classes.fieldsStyle}
                    value={firstName}
                    onChange={async (e) => {
                        e.persist();
                        await setFirstName(e.target.value)
                    }}
                />

                <TextField label="Last Name" variant="outlined" className={classes.fieldsStyle}
                    value={lastName}
                    onChange={async (e) => {
                        e.persist();
                        await setLastName(e.target.value)
                    }}
                />

                <TextField label="Email" variant="outlined" className={classes.fieldsStyle}
                    value={email}
                    onChange={async (e) => {
                        e.persist();
                        await setEmail(e.target.value)
                    }}
                />
                {data ? (
                    null
                ) : (<TextField label="Password" variant="outlined" className={classes.fieldsStyle}
                    value={password}
                    onChange={async (e) => {
                        e.persist();
                        await setPassword(e.target.value)
                    }}
                />)}
                <Select
                    labelId="role-select-label"
                    id="role-select" className={classes.fieldsStyle}
                    placeholder="Role"
                    value={role}
                    fullWidth
                    variant='outlined'
                    onChange={(e) => {
                        e.persist();
                        setRole(e.target.value)
                    }}
                >
                    <MenuItem value={'admin'}>Admin</MenuItem>
                    <MenuItem value={'user'}>User</MenuItem>
                </Select>

                <Button variant="contained" color="primary" style={{ marginTop: 20 }} onClick={data ? updateUser : addUser}>
                    {data ? "Update" : "Add"}
                </Button>
            </Grid>
        </form>
    );
}