import React from "react";
import { HashRouter as BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import axios from 'axios';

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";

// context
import { useUserState, useUserDispatch } from "../context/UserContext";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();
  var dispatch = useUserDispatch();
  // if (isAuthenticated) {
  // axios({
  //   method: 'post',
  //   url: 'http://127.0.0.1:8000/api/users/verifyToken',
  //   data: {
  //     token: ('bearer ' + localStorage.getItem("id_token"))
  //   }
  // }).then((response) => {
  //   if (response.status == 200) {
  //     console.log('true')
  //     // dispatch({ type: 'LOGIN_SUCCESS' })
  //   } else { dispatch({ type: "LOGIN_FAILURE" }); }
  // }, (error) => {
  //   dispatch({ type: "LOGIN_FAILURE" });
  //   window.location = '/login'
  // })
  // }
  return (
    <BrowserRouter>
      {/* {console.log(isAuthenticated)} */}
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/user" />} />
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute path="/login" component={Login} />
        <Route render={() => <Redirect to="/app" />} component={Layout} />
      </Switch>
    </BrowserRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
