import React, { useEffect } from "react";
import { Button, Grid, IconButton, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import DelelteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Dialog from './dialog';
import ChangePassDialog from './changePasswordDialog';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import axios from 'axios';


// import needed components, functions and styles
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import Table from "../dashboard/components/Table/Table";
import { Typography } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))

export default function Tables() {
  const classes = useStyles();
  const [datatableData, setDatatableData] = React.useState([]);
  //dialog state 
  const [open, setOpen] = React.useState(false);
  const [changePassOpen, setChangePassOpen] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [userName, setUserName] = React.useState('');
  const [rowValue, setRowValue] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };
  const handlePassDialogClose = () => {
    setChangePassOpen(false);
  };

  //handel axios errors
  const errorHandler = error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 403:
          alert('شما برای این عملیات دسترسی ها لازم را ندارید.')
          break;
        case 401:
          alert('شما برای این عملیات دسترسی ها لازم را ندارید.')
          break;
      }
      return
    }
    alert(error.response.status + '\n' + error.response.data)
    return
  }

  const getData = async () => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/users`,
      headers: {
        Authorization: ('bearer ' + localStorage.getItem("id_token"))
      }
    }).then((res, err) => {
      setDatatableData(res.data)
    }, (error) => {
      errorHandler(error)
    })
  }

  const deleteItem = async (id) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/users/${id}`, { headers: { Authorization: ('bearer ' + localStorage.getItem("id_token")) } }).then(() => {
      getData();
    });
  }

  useEffect(() => {
    getData();
  }, [])
  console.log(datatableData)
  return (
    <>
      <PageTitle title="Users" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Dialog id="dialog" open={open} close={handleClose} data={isUpdate ? rowValue : null} />
          <ChangePassDialog id="changePassDialog" close={handlePassDialogClose} open={changePassOpen} userName={userName} />
          <MUIDataTable
            title="users"
            data={datatableData}
            columns={[{ name: "id" }, {
              name: "password",
              options: {
                display: false,
              }
            }, { name: "firstName" }, { name: "lastName" }, { name: "email" }, {
              name: "role",
              label: "admin",
              options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    value === 'admin' ? (
                      <div style={{ justifyContent: 'start', alignItems: 'center', display: 'flex' }}>
                        <CheckCircleIcon style={{ color: 'green' }} />
                      </div>
                    ) : (
                      <div style={{ justifyContent: 'start', alignItems: 'center', display: 'flex' }}>
                        <HighlightOffIcon style={{ color: 'red' }} />
                      </div>
                    )
                  )
                }
              },
            },
            {
              name: "verified",
              options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    value ? (
                      <div style={{ justifyContent: 'start', alignItems: 'center', display: 'flex' }}>
                        <CheckCircleIcon style={{ color: 'green' }} />
                      </div>
                    ) : (
                      <div style={{ justifyContent: 'start', alignItems: 'center', display: 'flex' }}>
                        <HighlightOffIcon style={{ color: 'red' }} />
                      </div>
                    )
                  )
                }
              },
            }
              , {
              name: "options", options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <>
                      <DelelteIcon onClick={() => {
                        confirmAlert({
                          title: 'Confirm for remove',
                          closeOnEscape: true,
                          message: 'Are you sure to delete the user?',
                          overlayClassName: 'ModalOverlay',
                          ModalOverlay: {
                            zIndex: '1000 !important',
                          },
                          buttons: [
                            {
                              label: 'Yes',
                              onClick: () => { deleteItem(tableMeta.rowData[0]) }
                            },
                            {
                              label: 'No',
                              onClick: () => { }
                            }
                          ]
                        });
                      }} style={{ fontSize: 25, cursor: 'pointer', marginRight: 6, color: '#f00' }} />
                      <EditIcon style={{ fontSize: 25, cursor: 'pointer', marginRight: 6, }} onClick={async () => {
                        await setIsUpdate(true);
                        await setRowValue(tableMeta.rowData);
                        setOpen(true)
                      }} />
                      <VpnKeyIcon style={{ fontSize: 25, cursor: 'pointer', marginRight: 6, }} onClick={async () => {
                        await setUserName(tableMeta.rowData[0]);
                        setChangePassOpen(true);
                      }} />
                      {/* {console.log(open + isUpdate)} */}
                    </>
                  );
                }
              }
            },
            ]}
            options={{
              filterType: "SearchIcon",
              customToolbar: function () {
                return (
                  <>
                    <IconButton onClick={async () => {
                      await setIsUpdate(false);
                      setOpen(true)
                    }}>
                      <AddBoxIcon />
                    </IconButton>
                    <IconButton onClick={() => {
                      getData();
                    }}>
                      <RefreshIcon />
                    </IconButton>
                  </>
                )
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
