import React from "react";
import { Button, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import DelelteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Dialog from './dialog';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import axios from 'axios';

// components
import PageTitle from "../../components/PageTitle";
const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))

export default function Tables() {
  const classes = useStyles();
  const [dataTableData, setDataTableData] = React.useState([]);
  //dialog state 
  const [open, setOpen] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [rowValue, setRowValue] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };
  const getData = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/services`, {
      headers: {
        Authorization: ('bearer ' + localStorage.getItem("id_token"))
      }
    })
      .then((res, err) => {
        if (err) {
          console.log(err);
          return
        }
        setDataTableData(res.data)
      })
  };

  //handel axios errors
  const errorHandler = error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 403:
          // window.location.reload();
          window.location = '/login'
          break;
        case 401:
          // window.location.reload();
          window.location = '/login'
          break;
      }
      return
    }
    return
  };

  const deleteItem = async (id) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/services/${id}`, {
      headers: {
        Authorization: ('bearer ' + localStorage.getItem("id_token"))
      }
    }).then(() => {
      getData();
    }, error => {
      errorHandler(error)
    });
  }

  React.useEffect(() => {
    getData();
  }, [])

  return (
    <>
      <PageTitle title="Services" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Dialog id="dialog" open={open} close={handleClose} data={isUpdate ? rowValue : null} />
          <MUIDataTable
            resizableColumns={true}
            title="Services"
            data={dataTableData}
            columns={[{
              name: "id"
            }, { name: "name" }, { name: "description" },
            { name: "price" },
            {
              name: "isPopular",
              options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    value ? (
                      <div style={{ justifyContent: 'start', alignItems: 'center', display: 'flex' }}>
                        <CheckCircleIcon style={{ color: 'green' }} />
                      </div>
                    ) : (
                      <div style={{ justifyContent: 'start', alignItems: 'center', display: 'flex' }}>
                        <HighlightOffIcon style={{ color: 'red' }} />
                      </div>
                    )
                  )
                }
              },
            },
            {
              name: "lang",
            },
            {
              name: "actions", options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <>
                      <DelelteIcon onClick={() => {
                        confirmAlert({
                          title: 'Confirm for remove',
                          closeOnEscape: true,
                          message: 'Are you sure to delete the service?',
                          overlayClassName: 'ModalOverlay',
                          ModalOverlay: {
                            zIndex: '1000 !important'
                          },
                          buttons: [
                            {
                              label: 'Yes',
                              onClick: () => { deleteItem(tableMeta.rowData[0]) }
                            },
                            {
                              label: 'No',
                              onClick: () => { }
                            }
                          ]
                        });
                      }} style={{ fontSize: 25, cursor: 'pointer', marginRight: 6, color: '#f00' }} />
                      <EditIcon style={{ fontSize: 25, cursor: 'pointer', }} onClick={async () => {
                        await setIsUpdate(true);
                        await setRowValue(tableMeta.rowData);
                        setOpen(true);
                      }} />
                    </>
                  );
                }
              }
            }
            ]}
            options={{
              filterType: "SearchIcon",
              customToolbar: function () {
                return (
                  <>
                    <IconButton onClick={async () => {
                      await setIsUpdate(false);
                      setOpen(true);
                    }}>
                      <AddBoxIcon />
                    </IconButton>
                    <IconButton onClick={() => {
                      getData();
                    }}>
                      <RefreshIcon />
                    </IconButton>
                  </>
                )
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
