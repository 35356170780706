import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button, Grid } from '@material-ui/core';
import axios from 'axios';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

// select components
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// import needed components, functions and styles
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SettingsPhoneRounded } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        direction: 'column',
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    inputsGrid: {
        width: '100%'
    },
    //notifcation
    toastsContainer: {
        width: 400,
        marginTop: theme.spacing(6),
        right: 0,
    },
    fieldsStyle: {
        width: '100%', marginTop: 20
    }
}));

export default function _Form({ data }) {
    const classes = useStyles();
    const [id, setId] = React.useState(data ? data[0] : null);
    const [name, setName] = React.useState(data ? data[1] : '');
    const [description, setDescription] = React.useState(data ? data[2] : '');
    const [price, setPrice] = React.useState(data ? data[3] : null)
    const [lang, setLang] = React.useState(data ? data[4] : "en");

    //add member
    const addService = () => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/services`,
            data: {
                name,
                description,
                price: +price,
                lang,
            },
            headers: {
                Authorization: ('bearer ' + localStorage.getItem("id_token"))
            }
        }).then((response, err) => {
            toast.success('New service added successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.log({ err })
        }, (error) => {
            toast.error('Error! Please check your entries', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // errorHandler(error)
        })
    };
    //updateService
    const updateService = () => {
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/services/${id}`,
            data: {
                name,
                description,
                price: +price,
                lang,
            },
            headers: {
                Authorization: ('bearer ' + localStorage.getItem("id_token"))
            }
        }).then((response) => {
            response.status === 200 ? (
                toast.success('The service updated successfully.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })) : (
                toast.success(' ERROR! ', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }))
        }, (error) => {
            toast.error('Error! Please check your entries', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    };

    const handleLangChange = async (e) => {
        e.persist();
        await setLang(e.target.value)
    }
    return (
        <form id='aboutUsForm' className={classes.root} noValidate autoComplete="off">
            {/* {data ? setId(data[0]) : null}
            {console.log(id)} */}
            {/* <TextField id="standard-basic" label="Standard" />
      <TextField id="filled-basic" label="Filled" variant="filled" /> */}
            <Grid item container className={classes.inputsGrid}>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* Same as */}
                <ToastContainer />

                <TextField label="Title" variant="outlined" className={classes.fieldsStyle}
                    value={name}
                    onChange={async (e) => {
                        e.persist();
                        await setName(e.target.value)
                    }}
                />

                <TextField label="Description" variant="outlined" className={classes.fieldsStyle}
                    value={description}
                    onChange={async (e) => {
                        e.persist();
                        await setDescription(e.target.value)
                    }}
                />

                <TextField label="Price" variant="outlined" className={classes.fieldsStyle}
                    value={price}
                    type="number"
                    onChange={async (e) => {
                        e.persist();
                        await setPrice(e.target.value)
                    }}
                />

                <FormControl variant="outlined" className={classes.fieldsStyle}>
                    <InputLabel id="demo-simple-select-outlined-label">language</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={lang}
                        onChange={handleLangChange}
                        label="language"
                    >
                        <MenuItem value={"fa"}>فا</MenuItem>
                        <MenuItem value={"en"}>En</MenuItem>
                        <MenuItem value={"pt"}>Pt</MenuItem>
                    </Select>
                </FormControl>

                <Button variant="contained" color="primary" style={{ marginTop: 20 }} onClick={data ? updateService : addService}>
                    {data ? "Update" : "Save"}
                </Button>
            </Grid>
        </form>
    );
}