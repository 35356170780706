import React, { useState } from 'react'
import axios from 'axios';
import { Button, Grid, IconButton, TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    fieldsStyle: {
        width: '100%', marginTop: 20
    }
}))

export default function View({ id }) {
    const classes = useStyles()
    const [request, setRequest] = useState()
    console.log(id)
    const getData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/requests/${id}`, {
            headers: {
                Authorization: ('bearer ' + localStorage.getItem("id_token"))
            }
        })
            .then((res, err) => {
                setRequest(res.data)
            })
    };
    React.useEffect(() => {
        getData();
    }, [])
    console.log(request)
    return (
        <Grid>
            {request?.files?.map(file => {
                return (
                    <img onClick={() => window.open(`${process.env.REACT_APP_API_URL}/${file.path}`, "_blank")}
                        style={{ width: '30%' }}
                        src={`${process.env.REACT_APP_API_URL}/${file.path}`}
                        alt=''
                    />
                )
            })}
            <TextField label="Full Name" variant="outlined" className={classes.fieldsStyle}
                value={request?.fullName ? request.fullName : ""}
                onChange={async (e) => {
                }}
            />
            <TextField label="Full Address" variant="outlined" className={classes.fieldsStyle}
                value={request?.address ? request.address : ""}
                onChange={async (e) => {
                }}
            />
            <TextField label="Email" variant="outlined" className={classes.fieldsStyle}
                value={request?.email ? request.email : ""}
                onChange={async (e) => {
                }}
            />

            <TextField label="Passport Number" variant="outlined" className={classes.fieldsStyle}
                value={request?.passportNumber ? request.passportNumber : ""}
                onChange={async (e) => {
                }}
            />
            <TextField label="Using Nif For?" variant="outlined" className={classes.fieldsStyle}
                value={request?.usingNifFor ? request.usingNifFor : ""}
                onChange={async (e) => {
                }}
            />
            <TextField label="Visa Type" variant="outlined" className={classes.fieldsStyle}
                value={request?.visaType ? request.visaType : ""}
                onChange={async (e) => {
                }}
            />
            <TextField label="Whatsapp Number" variant="outlined" className={classes.fieldsStyle}
                value={request?.whatsapp ? request.whatsapp : ""}
                onChange={async (e) => {
                }}
            />
            <TextField label="Birthday" variant="outlined" className={classes.fieldsStyle}
                value={request?.birthday ? request.birthday : ""}
                onChange={async (e) => {
                }}
            />
            <TextField label="Passport Issue Date" variant="outlined" className={classes.fieldsStyle}
                value={request?.passportIssueDate ? request.passportIssueDate : ""}
                onChange={async (e) => {
                }}
            />
            <TextField label="Passport Expiration Date" variant="outlined" className={classes.fieldsStyle}
                value={request?.passportExpirationDate ? request.passportExpirationDate : ""}
                onChange={async (e) => {
                }}
            />

        </Grid>
    )
}
