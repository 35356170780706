import React from "react";
import axios from 'axios';

// import needed components, functions and styles
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var UserStates = React.createContext({
  first_name: 'uesr',
  changeFirstName: () => { }
});
var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

// var [first_name, setFirst_name] = React.useState('')

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [firstName, setFirst_name] = React.useState('');
  const changeFirstName = (name) => {
    setFirst_name(name)
  }
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: localStorage.getItem("id_token") && axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/users/me`,
      headers: {
        Authorization: ('bearer ' + localStorage.getItem("id_token"))
      }
    }).then((response) => {
      if (response.status === 200) {
        changeFirstName(response.data.first_name)
      }
    }, (error) => {
      localStorage.clear();
      dispatch({ type: "LOGIN_FAILURE" });
    })
  });

  return (
    <UserStates.Provider value={{
      first_name: firstName,
      changeFirstName: changeFirstName
    }}>
      <UserStateContext.Provider value={state}>
        <UserDispatchContext.Provider value={dispatch}>
          {children}
        </UserDispatchContext.Provider>
      </UserStateContext.Provider>
    </UserStates.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, UserStates };

// ###########################################################

async function loginUser(dispatch, login, password, history, setIsLoading, setError, UserStates) {
  setError(false);
  setIsLoading(true);
  console.log(process.env.REACT_APP_API_URL)
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/auth/login`,
    data: {
      email: login,
      password: password,
    }
  }).then((response) => {
    if (response.status == 200) {
      console.log(response)
      setError(null)
      localStorage.setItem('id_token', response.data.access_token)
      setIsLoading(false)
      dispatch({ type: 'LOGIN_SUCCESS' })
      // // console.log(response);
      history.push('/')
    }
  }, (error) => {
    setError(true);
    setIsLoading(false);
    // dispatch({ type: "LOGIN_FAILURE" });
    return
  })

}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}
