import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button, Grid } from '@material-ui/core';
import axios from 'axios';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

// select components
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// import needed components, functions and styles
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SettingsPhoneRounded } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        direction: 'column',
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    inputsGrid: {
        width: '100%'
    },
    //notifcation
    toastsContainer: {
        width: 400,
        marginTop: theme.spacing(6),
        right: 0,
    },
    fieldsStyle: {
        width: '100%', marginTop: 20
    }
}));

export default function _Form({ data }) {
    const classes = useStyles();
    const [id, setId] = React.useState(data ? data[0] : null);
    const [title, setTitle] = React.useState(data ? data[1] : '');
    const [type, setType] = React.useState(data ? data[2] : false);
    const [serviceId, setServiceId] = React.useState(data ? data[3] : '');

    //add member
    const addService = () => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/service-options`,
            data: {
                title,
                type,
                serviceId: +serviceId
            },
            headers: {
                Authorization: ('bearer ' + localStorage.getItem("id_token"))
            }
        }).then((response, err) => {
            toast.success('New option added successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.log({ err })
        }, (error) => {
            toast.error('Error! Please check your entries', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // errorHandler(error)
        })
    };
    //updateService
    const updateService = () => {
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/service-options/${id}`,
            data: {
                title,
                type,
                serviceId: +serviceId
            },
            headers: {
                Authorization: ('bearer ' + localStorage.getItem("id_token"))
            }
        }).then((response) => {
            response.status === 200 ? (
                toast.success('The option updated successfully.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })) : (
                toast.success(' ERROR! ', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }))
        }, (error) => {
            toast.error('Error! Please check your entries', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    };

    return (
        <form id='aboutUsForm' className={classes.root} noValidate autoComplete="off">
            <Grid item container className={classes.inputsGrid}>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* Same as */}
                <ToastContainer />

                <TextField label="Title" variant="outlined" className={classes.fieldsStyle}
                    value={title}
                    onChange={async (e) => {
                        e.persist();
                        await setTitle(e.target.value)
                    }}
                />

                <TextField label="Service Id" variant="outlined" className={classes.fieldsStyle}
                    value={serviceId}
                    type='number'
                    onChange={async (e) => {
                        e.persist();
                        await setServiceId(e.target.value >= 0 ? e.target.value : 0)
                    }}
                />

                <FormControlLabel
                    style={{ width: '100%' }}
                    className={classes.formControlLabel}
                    control={<Switch checked={type} onChange={(e) => {
                        e.persist();
                        setType(e.target.checked)
                    }} />}
                    label="Type"
                />

                <Button variant="contained" color="primary" style={{ marginTop: 20 }} onClick={data ? updateService : addService}>
                    {data ? "Update" : "Save"}
                </Button>
            </Grid>
        </form>
    );
}