import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button, Grid } from '@material-ui/core';
import axios from 'axios';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import DragDrop from '../../components/Upload/DragDrop'
// import { DropzoneArea } from 'material-ui-dropzone';
// import ButtonUpload from '../../components/Upload/ButtonUpload'

// select components
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// import needed components, functions and styles
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        direction: 'column',
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    inputsGrid: {
        width: '100%'
    },
    //notifcation
    toastsContainer: {
        width: 400,
        marginTop: theme.spacing(6),
        right: 0,
    },
    fieldsStyle: {
        width: '100%', marginTop: 20
    }
}));

export default function _Form({ data }) {
    const classes = useStyles();
    const [userName, setUserName] = React.useState(data);
    const [newPassword, setNewPassword] = React.useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = React.useState('');
    const [btnDisable, setBtnDisable] = React.useState(true);
    //handel axios errors
    const errorHandler = error => {
        if (error.response.status) {
            switch (error.response.status) {
                case 403:
                    toast.error('شما برای این عملیات دسترسی ها لازم را ندارید.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    break;
                case 401:
                    toast.error('شما برای این عملیات دسترسی ها لازم را ندارید.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    break;
                case 400:
                    toast.error('لطفا ورودی ها خود را کنترل کنید!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    break;
                case 500:
                    toast.error('مشکلی پیش امده است لطفا بعدا امتحان کنید', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    break;
            }
            return
        }
        toast.error(error.response.status + '\n' + error.response.data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
        return
    }

    //add slide
    const changePassword = () => {
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/users/change-password/${userName}`,
            data: {
                password: newPassword,
                confirmPassword: newPasswordConfirm,
            },
            headers: {
                Authorization: ('bearer ' + localStorage.getItem("id_token"))
            }
        }).then((response) => {
            toast.success('Password changed successfully.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }, (error) => {
            errorHandler(error)
        })
    };
    return (
        <form id='sliderForm' className={classes.root} noValidate autoComplete="off">
            <Grid item container className={classes.inputsGrid}>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* Same as */}
                <ToastContainer />
                <TextField label="New Password" variant="outlined" className={classes.fieldsStyle}
                    value={newPassword}
                    onChange={async (e) => {
                        e.persist();
                        await setNewPassword(e.target.value)
                    }}
                />
                <TextField label="Password Confirm" variant="outlined" className={classes.fieldsStyle}
                    value={newPasswordConfirm}
                    onChange={async (e) => {
                        e.persist();
                        await setNewPasswordConfirm(e.target.value);
                        e.target.value === newPassword ? await setBtnDisable(false) : await setBtnDisable(true)
                    }}
                />
                <Button variant="contained" color="primary" style={{ marginTop: 20 }} disabled={btnDisable} onClick={() => { changePassword(); }} >
                    Change Password
                </Button>
            </Grid>
        </form>
    );
}